import { RenderRTE } from '@/components/RenderRTE';
import {
    MediaWithTextGroup as MediaWithTextInRow,
    MediaWithTextSingle,
    MediaWithText,
} from '@/components/molecules/MediaWithText';
import { Slider } from '@/components/molecules/Slider';
import { MediaWithCarouselOptions } from '@/components/molecules/Slider/SliderOptions';
import PulseCarousel from '@/components/molecules/PulseCarousel/PulseCarousel';
import type { MediaWithTextProps } from '@/components/molecules/MediaWithText/interfaces';
import type { Variant, VariantMap } from '@/components/molecules/MediaWithText/interfaces';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';

type MediaWithTextPropsArray = MediaWithTextProps[];
type PresentationStyles = { [key: string]: string };

const VARIANT_MAP: VariantMap = {
    'Two per row': '2inRow',
    'Three per row': '3inRow',
    'Four per row': '4inRow',
};

const LAYOUT_12_COL = 'Twelve column layout';
const IMAGE_ALIGN_RIGHT = 'Right';
const IMAGE_SMALL_STYLE = 'Small';

function renderPingPongTemplate(
    mediaWithTextProps: MediaWithTextPropsArray,
    presentationStyles?: PresentationStyles,
): JSX.Element {
    const imageReversed = presentationStyles && presentationStyles['Image Align'] === IMAGE_ALIGN_RIGHT;
    const isTwelveColumn = presentationStyles && presentationStyles['Layout'] === LAYOUT_12_COL;
    const isImageSmallStyle = presentationStyles && presentationStyles['Image Size'] === IMAGE_SMALL_STYLE;
    return (
        <MediaWithTextSingle
            isTwelveColumn={isTwelveColumn}
            isReversed={imageReversed}
            isImageSmallStyle={isImageSmallStyle}
            items={mediaWithTextProps}
        />
    );
}

function renderOtherTemplates(
    mediaWithTextProps: MediaWithTextPropsArray,
    presentationStyles?: PresentationStyles,
): JSX.Element {
    const variant = presentationStyles?.variant || 'Two per row';
    const isFeatured = presentationStyles?.['Featured'] === 'Yes';
    const isTwelveColumn = presentationStyles?.['Layout'] === LAYOUT_12_COL;

    const mediWithTextVariant: Variant = VARIANT_MAP[variant];

    if (!isFeatured) {
        return (
            <MediaWithTextInRow
                isTwelveColumn={isTwelveColumn}
                variant={mediWithTextVariant}
                items={mediaWithTextProps}
            />
        );
    } else {
        const [mediaWithFeaturedProp, ...remainingMediaWithTextProps] = mediaWithTextProps;

        return (
            <>
                <MediaWithTextSingle
                    isTwelveColumn={isTwelveColumn}
                    isReversed={false}
                    items={[mediaWithFeaturedProp]}
                />

                <MediaWithTextInRow
                    isTwelveColumn={isTwelveColumn}
                    variant={mediWithTextVariant}
                    items={remainingMediaWithTextProps}
                    featured={true}
                />
            </>
        );
    }
}

function renderCarouselTemplate(mediaWithTextProps: MediaWithTextPropsArray, presentationStyles?: PresentationStyles) {
    const isMediaDownload = presentationStyles && presentationStyles['Link Type'] === 'Media Download';

    const slides = mediaWithTextProps.map((media, index) => {
        return { content: <MediaWithText key={index} {...media} imageDownload={isMediaDownload} /> };
    });

    const tenColumnLayout = presentationStyles?.Layout === 'Ten Column';

    return (
        <Grid container>
            <Grid col={tenColumnLayout ? { xs: 12, lg: 10 } : { xs: 12 }} start={tenColumnLayout ? { lg: 2 } : {}}>
                <Slider
                    slides={slides}
                    isThemeDark={false}
                    isArrowsTopAligned={false}
                    splideOptions={{ ...MediaWithCarouselOptions, label: 'Select a slide to show' }}
                    type="Default"
                />
            </Grid>
        </Grid>
    );
}

function renderPulseCarouselTemplate(mediaWithTextProps: MediaWithTextPropsArray) {
    return <PulseCarousel mediaWithTextProps={mediaWithTextProps}></PulseCarousel>;
}

export default function MediaWithTextGroup(props: SiteAssetGroup): JSX.Element {
    const asW2 = props.pageContext?.properties?.web2styles === 'true';
    const mediaWithTextList = props.fields.assets as Array<SiteMediaWithText>;
    const presentation = props.presentation?.presentations.MediaWithText;
    const template = presentation?.template;
    const styles = presentation?.styles;

    const mediaWithTextProps: MediaWithTextProps[] = mediaWithTextList.map((asset) => {
        const Link = asset.fields.link?.fields as Link;
        let mediaProp = {};

        const {
            fields: { headline, description, media, audio, channel_association },
        } = asset;

        const descriptionText = <RenderRTE contentfulDoc={description} asW2={asW2} />;

        if (media) {
            const { format, url, altText } = media;
            mediaProp =
                format === 'video'
                    ? { video: { source: url, altText } }
                    : { image: { source: url, altText, width: 800, height: 0 } };
        }

        const audioProp = audio ? { source: audio.url, name: audio.altText } : undefined;

        return {
            headline,
            body: descriptionText,
            link: Link,
            audio: audioProp,
            imageSize: template === 'Icon' ? 'Icon' : 'Standard',
            channelAssociation: channel_association,
            ...mediaProp,
        };
    });

    const render = {
        PingPong: renderPingPongTemplate,
        Carousel: renderCarouselTemplate,
        PulseCarousel: renderPulseCarouselTemplate,
    };

    if (render[template as keyof typeof render]) {
        return render[template as keyof typeof render](mediaWithTextProps, styles);
    } else {
        return renderOtherTemplates(mediaWithTextProps, styles);
    }
}
