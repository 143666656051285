import dynamic from 'next/dynamic';
import { ContentfulComponent } from './type';
const PromoBanner = dynamic(() => import('./PromoBanner'));
const NavigationFooter = dynamic(() => import('./NavigationFooter'));
const AssetGroup = dynamic(() => import('./AssetGroup/AssetGroup'));
const Header = dynamic(() => import('./Header'));
const Link = dynamic(() => import('./Link'));
const EntityTile = dynamic(() => import('./EntityTile'));
const HeroHeaderImage = dynamic(() => import('./HeroHeaderImage'));
const UpcomingSports2_0 = dynamic(() => import('./GameCard'));
const EntityPromoItem = dynamic(() => import('./EntityPromoItem/EntityPromoItem'));
const ChannelGuide = dynamic(() => import('./ChannelGuide/ChannelGuide'));
const CampaignBanner = dynamic(() => import('./CampaignBanner/CampaignBanner'));
const MediaWithContentList = dynamic(() => import('./MediaWithContentList/MediaWithContentList'));
const StaticTestContent = dynamic(() => import('./StaticTestContent/StaticTestContent'));

import { getComponent as getWeb1Component } from '@/components/contentful';

interface ComponentList {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [index: string]: ContentfulComponent<any extends SiteAsset ? any : never>;
}

const componentList: ComponentList = {
    navigationfooter: NavigationFooter,
    assetgroup: AssetGroup,
    promobanner: PromoBanner,
    header: Header,
    link: Link,
    entityhero: EntityTile,
    hero_2_0: HeroHeaderImage,
    upcomingsports: UpcomingSports2_0,
    entitypromoitem: EntityPromoItem,
    channelguide: ChannelGuide,
    campaignbanner: CampaignBanner,
    mediawithcontentlist: MediaWithContentList,
    statictestcontent: StaticTestContent,
};

export function getContentfulComponent(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ContentfulComponent<any extends SiteAsset ? any : never> | undefined {
    let component = componentList[name.toLowerCase()];

    if (!component) {
        component = getWeb1Component(name);
    }

    return component;
}
