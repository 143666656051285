import dynamic from 'next/dynamic';
import Link from './Link'; // Non-dynamic import to stop button-related [CLS](https://web.dev/articles/cls) issues
import AssetGroup from './AssetGroup/AssetGroup';
import Header from './Header';
import {Hero} from './Hero';

const TextContent = dynamic(() => import('./TextContent/TextContent'));
const Youtube = dynamic(() => import('./Youtube'));
const Action = dynamic(() => import('./Action'));
const StaticContent = dynamic(() => import('./StaticContent'));
const Media = dynamic(() => import('./Media'));
const ChannelFeaturedShow = dynamic(() => import('./FeaturedShow'));
const ContextualComponent = dynamic(() => import('./ContextualComponent'));
const ContentShowcase = dynamic(() => import('./ContentShowcase'));
const PromoOffer = dynamic(() => import('./PromoOffer'));
const Blog = dynamic(() => import('./Blog'));
const VODPreview = dynamic(() => import('./VODPreview'));
const ChannelGuide = dynamic(() => import('./ChannelGuide'));
const UpcomingSports = dynamic(() => import('./UpcomingSports'));
const NavigationFooter = dynamic(() => import('./NavigationFooter'));
const NavigationHeader = dynamic(() => import('./NavigationHeader'));
const OfferComparison = dynamic(() => import('./OfferComparison'));
const LandingTitle = dynamic(() => import('./LandingTitle'));
const SundaySkyVideo = dynamic(() => import('./SundaySkyVideo'));
const StaticTestContent = dynamic(() => import('@/components-2.0/contentful/StaticTestContent/StaticTestContent'));

interface ComponentList {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [index: string]: any;
}

const componentList: ComponentList = {
    assetgroup: AssetGroup,
    header: Header,
    hero: Hero,
    link: Link,
    textcontent: TextContent,
    youtube: Youtube,
    action: Action,
    staticcontent: StaticContent,
    media: Media,
    featuredshow: ChannelFeaturedShow,
    contextualcomponent: ContextualComponent,
    contentshowcase: ContentShowcase,
    promooffer: PromoOffer,
    blog: Blog,
    vodpreview: VODPreview,
    channelguide: ChannelGuide,
    upcomingsports: UpcomingSports,
    navigationfooter: NavigationFooter,
    navigationheader: NavigationHeader,
    offercomparison: OfferComparison,
    motiongraphicmodule: ContentShowcase,
    landingtitle: LandingTitle,
    sundayskyvideo: SundaySkyVideo,
    statictestcontent: StaticTestContent,
};

export function getComponent(name: string) {
    return componentList[name.toLowerCase()];
}
