import { ReactNode } from 'react';
import cn from 'classnames';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { Link } from '@/components/atoms/Link';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import styles from './featuregroup.module.scss';
export interface FeatureGroupItemProps {
    image: {
        src: string;
        alt: string;
        maxWidth?: string;
        maxHeight?: string;
    };
    itemTitle: ReactNode;
    itemText: ReactNode;
    link?: Link;
    fullWidth?: boolean;
}

const FeaturedGroupItem = ({ image, itemTitle, itemText, link, fullWidth }: FeatureGroupItemProps) => {
    const { src, alt, maxHeight, maxWidth } = image;

    return (
        <Grid container className={styles.featuredGroupItem}>
            <Grid
                col={fullWidth ? { sm: 6, md: 2 } : { sm: 6, md: 3, lg: 2 }}
                className={styles.imageWrapper}
                {...(fullWidth ? {} : { start: { lg: 2 } })}
            >
                <ImageWrapper src={src} alt={alt} style={{ maxWidth, maxHeight }} aria-hidden />
            </Grid>
            <Grid col={fullWidth ? { sm: 12, md: 8 } : { sm: 12, md: 9, lg: 8 }} className={styles.textWrapper}>
                <Text tag="p">{itemTitle}</Text>
                <Text tag="p" className={styles.itemText}>
                    {itemText}
                </Text>
                {link?.href && (
                    <Text tag="p" className={styles.linkStyles}>
                        <Link href={link.href} aria-label={link.aria_label} target={link.link_target}>
                            {link.link_text}
                        </Link>
                    </Text>
                )}
            </Grid>
        </Grid>
    );
};

interface FeatureGroupProps {
    items: FeatureGroupItemProps[];
    bottomBorder?: boolean;
    fullWidth?: boolean;
}

export default function FeatureGroup({
    items,
    bottomBorder = false,
    fullWidth = false,
}: FeatureGroupProps): JSX.Element {
    const featureGroupItems = items.map(({ image, itemTitle, itemText, link }, index) => {
        const content = (
            <li
                key={index}
                className={cn(styles.listItem, {
                    [styles.bottomBorder]: bottomBorder,
                })}
            >
                <FeaturedGroupItem
                    image={image}
                    itemTitle={itemTitle}
                    itemText={itemText}
                    link={link}
                    fullWidth={fullWidth}
                />
            </li>
        );

        return content;
    });

    return (
        <div className={styles.mainWrapper}>
            <ul className={styles.featureGroupItemsWrapper}>{featureGroupItems}</ul>
        </div>
    );
}
