import styles from './offericons.module.scss';
import cn from 'classnames';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface OfferIconsProps {
    icons: ImageProps[];
    desktopWhiteFilter?: boolean;
    whiteFilter?: boolean;
    className?: string;
}

export default function OfferIcons({
    icons,
    desktopWhiteFilter = true,
    className,
    whiteFilter = false,
}: OfferIconsProps): JSX.Element {
    const classNames = cn(styles.offerIconsList, className, {
        [styles.desktopWhiteFilter]: desktopWhiteFilter,
        [styles.whiteFilter]: whiteFilter,
    });

    return (
        <ul className={classNames}>
            {icons.map((icon, i) => {
                return (
                    <li key={i}>
                        <ImageWrapper
                            optimizedWidth={48}
                            optimizedHeight={48}
                            src={icon.source}
                            alt={icon.altText ?? ''}
                        />
                    </li>
                );
            })}
        </ul>
    );
}
