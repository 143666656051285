import dynamic from 'next/dynamic';
import { switchCase } from '@/packages/switch';
import RenderAsset from '@/components/RenderAsset';
import { PageContext } from '@/interfaces/types/PageContext';
import FeatureGroup from './Group/FeatureGroup';
import VehicleFeatures from './VehicleFeatures';
import MediaWithTextGroup from './Group/MediaWithTextGroup';

const ArticleGroup = dynamic(() => import('./Group/ArticleGroup'));
const OfferGroup = dynamic(() => import('./Group/OfferGroup'));
const DataPackageGroup = dynamic(() => import('./Group/DataPackageGroup'));
const SportLeagueGroup = dynamic(() => import('./Group/SportLeagueGroup'));
const GenreGroup = dynamic(() => import('./Group/GenreGroup'));
const LinkGroup = dynamic(() => import('./Group/LinkGroup/LinkGroup'));
const UserEngagementGroup = dynamic(() => import('./Group/UserEngagementGroup'));
const DeviceGroup = dynamic(() => import('./Group/DeviceGroup'));
const ChannelGroup = dynamic(() => import('./Group/ChannelGroup'));
const PartnerGroup = dynamic(() => import('./Group/PartnerGroup'));
const TabsGroup = dynamic(() => import('./Group/TabsGroup'));
const MultiProgramGroup = dynamic(() => import('./Group/MultiProgramGroup'));
const FeaturedContent = dynamic(() => import('./Group/FeaturedContent'));
const FAQGroup = dynamic(() => import('./Group/FAQGroup'));
const SweepsGroup = dynamic(() => import('./Group/SweepsGroup'));
const ContentSampling = dynamic(() => import('../../molecules/ContentSampling/ContentSampling'));
const MediaWithContentGroup = dynamic(() => import('./Group/MediaWithContent/MediaWithContentGroup'));

interface Props {
    index?: number;
    asset: SiteAssetGroup;
    pageContext?: PageContext;
}

type AssetKeys = AssetGroupType;

function toRenderAsset(asset: SiteAssetGroup, pageContext?: PageContext) {
    return function renderAsset(child: SiteAsset) {
        const presentation = asset.presentation?.presentations[child.cms_type];
        const data = {
            ...child,
            initialBackground: asset.initialBackground,
            isInTabsGroup: asset.isInTabsGroup,
        };

        return (
            <RenderAsset
                key={child.cms_id}
                asset={data}
                presentation={presentation}
                pageContext={pageContext}
                isInAssetGroup
            />
        );
    };
}

function assetGroup(asset: SiteAssetGroup, pageContext?: PageContext): React.ReactNode {
    if (asset.presentation?.presentations?.AssetGroup?.template === 'VehicleFeatures') {
        return <VehicleFeatures assets={asset.fields.assets} />;
    }

    return <TabsGroup {...asset} pageContext={pageContext} />;
}

export function proccessAssetType(asset: SiteAssetGroup, pageContext?: PageContext) {
    return switchCase<AssetKeys, JSX.Element>({
        Channel: <ChannelGroup {...asset} pageContext={pageContext} />,
        Sweeps: <SweepsGroup {...asset} pageContext={pageContext} />,
        FAQ: <FAQGroup {...asset} pageContext={pageContext} />,
        FeaturedContent: <FeaturedContent {...asset} pageContext={pageContext} />,
        MediaWithText: <MediaWithTextGroup {...asset} pageContext={pageContext} />,
        AssetGroup: <>{assetGroup(asset, pageContext)}</>,
        OEMPartner: <PartnerGroup {...asset} pageContext={pageContext} />,
        MarketingPartner: <PartnerGroup {...asset} pageContext={pageContext} />,
        MultiProgram: <MultiProgramGroup {...asset} pageContext={pageContext} />,
        HardwarePartner: <PartnerGroup {...asset} pageContext={pageContext} />,
        Device: <DeviceGroup {...asset} pageContext={pageContext} />,
        UserEngagement: <UserEngagementGroup {...asset} pageContext={pageContext} />,
        Link: <LinkGroup {...asset} />,
        Genre: <GenreGroup {...asset} />,
        'Sport-League-Group': <SportLeagueGroup {...asset} />,
        Feature: <FeatureGroup {...asset} pageContext={pageContext} />,
        DataPackage: <DataPackageGroup {...asset} pageContext={pageContext} />,
        Offer: <OfferGroup {...asset} pageContext={pageContext} />,
        Article: <ArticleGroup {...asset} />,
        ContentSampling: <ContentSampling {...asset} pageContext={pageContext} />,
        EntityList: <></>,
        Any: <>{asset?.fields?.assets?.map(toRenderAsset(asset, pageContext))}</>,
        GridLayout: <>{asset?.fields?.assets?.map(toRenderAsset(asset, pageContext))}</>,
        EntityCategory: <></>,
        EntityImageTile: <></>,
        EntityVideoTile: <></>,
        ContentCard: <></>,
        DeviceGroup: <></>,
        MediaWithContent: <MediaWithContentGroup {...asset} pageContext={pageContext} />,
        EntityHero: <></>,
    })(<></>);
}

export default function AssetGroup({ asset, pageContext }: Props): JSX.Element {
    return proccessAssetType(asset, pageContext)(asset?.fields?.type as AssetKeys);
}
