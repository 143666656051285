import styles from './headline.module.scss';
import dynamic from 'next/dynamic';
import cn from 'classnames';
const Breadcrumbs = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Breadcrumbs/Breadcrumbs'));
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import { Cols } from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid';
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import { TextClasses, TextTags } from 'de-sxm-reactlib/dist/components/atoms/Display/Text';
import ImageWrapper, { getBackgroundImage } from '@/shared-components/ImageWrapper/ImageWrapper';

type HeadlineVariant = 'Hero' | 'Legal' | 'Listen';
export type Alignment = 'left' | 'center';

interface Props {
    backgroundColor?: string;
    backgroundImageMobile?: string;
    backgroundImageTablet?: string;
    textColor?: string;
    desktopCols: Cols;
    tag: TextTags;
    className?: TextClasses;
    children: React.ReactNode;
    eyebrow?: string;
    subhead?: string | React.ReactNode;
    subClassName?: TextClasses;
    mainContainerClassName?: string;
    standardMoleculeSpacing?: boolean;
    variant?: HeadlineVariant;
    breadcrumbs?: Link[];
    align?: Alignment;
    subHeadStyles?: string;
    foregroundImage?: {
        tablet?: Partial<ImageProps>;
        mobile?: Partial<ImageProps>;
        stickToBottom?: boolean;
    };
}

export default function Headline({
    backgroundColor,
    textColor,
    desktopCols = 12,
    tag,
    className,
    children,
    eyebrow,
    subhead,
    subClassName,
    mainContainerClassName,
    standardMoleculeSpacing,
    variant,
    breadcrumbs,
    align,
    backgroundImageTablet,
    backgroundImageMobile,
    subHeadStyles,
    foregroundImage,
}: Props): JSX.Element {
    const colStartLimiter = desktopCols < 10 ? 10 : desktopCols;
    const desktopStart = (12 - colStartLimiter) as Cols;
    const isListenVariant = variant === 'Listen';

    const mainWrapperStyles = cn(mainContainerClassName, {
        [styles.listenWrapper]: isListenVariant,
    });

    const containerClasses = cn(styles.headlineContainer, {
        [styles[`${variant}`]]: variant,
        [styles.withBreadcrumbs]: breadcrumbs,
    });

    const headingClasses = cn(styles.heading, {
        [styles.center]: align === 'center',
        [styles.pinToBottom]: isListenVariant,
    });

    const EyebrowElement = () =>
        eyebrow ? (
            <Text tag="div" variant="eyebrowLg">
                {eyebrow}
            </Text>
        ) : null;

    const breadcrumbsItems = breadcrumbs?.map((e) => ({
        href: e.href,
        target: e.link_target,
        children: e.link_text,
    }));

    const content = isListenVariant ? (
        <div className={containerClasses}>
            <div className={styles.contentWrapper}>
                {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} />}
                <EyebrowElement />
                <ImageWrapper
                    src={foregroundImage?.tablet?.source ?? ''}
                    alt={foregroundImage?.tablet?.altText ?? ''}
                    className={styles.foregroundImageStyles}
                />
                <Text tag={tag} variant={className} className={headingClasses} dataCy="molecules-headline-text">
                    {children}
                </Text>
                {subhead && (
                    <Text
                        tag="div"
                        variant={subClassName}
                        className={subHeadStyles}
                        dataCy="molecules-headline-sub-text"
                    >
                        {subhead}
                    </Text>
                )}
            </div>
        </div>
    ) : (
        <Grid container className={containerClasses}>
            <Grid col={{ xs: 12, md: desktopCols }} start={{ md: desktopStart }}>
                {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} />}
                <EyebrowElement />
                <Text tag={tag} variant={className} className={headingClasses} dataCy="molecules-headline-text">
                    {children}
                </Text>
                {subhead && (
                    <Text
                        tag="div"
                        variant={subClassName}
                        className={subHeadStyles}
                        dataCy="molecules-headline-sub-text"
                    >
                        {subhead}
                    </Text>
                )}
            </Grid>
        </Grid>
    );

    return (
        <StyledContainer
            backgroundColor={backgroundColor}
            backgroundImageTablet={getBackgroundImage({ src: backgroundImageTablet })}
            backgroundImageMobile={getBackgroundImage({ src: backgroundImageMobile })}
            textColor={textColor}
            standardMoleculeSpacing={standardMoleculeSpacing}
            includeNavPadding={variant === 'Hero'}
            className={mainWrapperStyles}
        >
            {content}
        </StyledContainer>
    );
}
