import cn from 'classnames';
import { ReactNode } from 'react';
import { OfferIcons } from '@/components/atoms/OfferIcons';
import styles from '../hero.module.scss';
import { SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import { type ButtonProps } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import Breadcrumbs from 'de-sxm-reactlib/dist/components/atoms/Breadcrumbs/Breadcrumbs';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import Button from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import SocialMediaIconLinks from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks/SocialMediaIconLinks';

interface Props {
    breadcrumbs?: Link[];
    description?: ReactNode | ReactNode[];
    header: ReactNode | ReactNode[];
    subhead?: ReactNode | ReactNode[];
    priceSubhead?: React.ReactNode | React.ReactNode[];
    isSubheadNarrow?: boolean;
    mobileFlatBackground?: boolean;
    cta?: ButtonProps;
    offerIcons?: ImageProps[];
    socialMedia?: SocialMediaIconLinksProps;
    socialMediaText?: string;
    socialMediaStickToBottom?: boolean;
    underCtaText?: React.ReactNode | React.ReactNode[];
    customComponent?: React.ReactNode;
    className?: string;
    darkTheme?: boolean;
    hideCtaOnMobile?: boolean;
}

export default function HeroText(props: Props) {
    const classNames = cn(styles.heroText, styles.layoutPadding, props.className, {
        [styles.withBreadcrumbs]: props.breadcrumbs,
        [styles.subheadNarrow]: props.isSubheadNarrow,
        [styles.mobileFlatBackground]: props.mobileFlatBackground,
    });

    const ctaClassNames = cn(styles.ctaButton, {
        [styles.hideCtaOnMobile]: props.hideCtaOnMobile,
    });

    const whiteFilter = !props.mobileFlatBackground && props.darkTheme;
    const desktopWhiteFilter = props.mobileFlatBackground && props.darkTheme;

    const breadcrumbsItems = props.breadcrumbs?.map((e) => ({
        href: e.href,
        target: e.link_target,
        children: e.link_text,
    }));

    return (
        <div className={classNames}>
            {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} />}
            {props.header && (
                <Text tag="h1" variant="h1" dataCy="molecules-hero-text-headline" className={styles.heroTextHeader}>
                    {props.header}
                </Text>
            )}

            {props.priceSubhead && (
                <Text tag="p" variant="h4" className={styles.priceSubhead}>
                    {props.priceSubhead}
                </Text>
            )}

            {props.subhead && (
                <div className={cn(styles.subhead, 'p')}>
                    <Text tag="span" variant="subhead">
                        {props.subhead}
                    </Text>
                </div>
            )}

            {props.description && <Text tag="p">{props.description}</Text>}

            {props.offerIcons && props.offerIcons.length > 0 && (
                <OfferIcons
                    icons={props.offerIcons}
                    className={styles.offerIcons}
                    whiteFilter={whiteFilter}
                    desktopWhiteFilter={desktopWhiteFilter}
                />
            )}

            {props.cta && <Button {...props.cta} className={ctaClassNames} dataCy="molecules-hero-text-button" />}

            {props.underCtaText && (
                <Text tag="p" className={styles.heroUnderCtaText}>
                    {props.underCtaText}
                </Text>
            )}

            {props.socialMedia && props.socialMedia.items.length > 0 && (
                <div
                    className={cn(styles.socialMediaBlock, {
                        [styles.socialMediaBlockStickToBottom]: props.socialMediaStickToBottom,
                    })}
                >
                    {props.socialMediaText && (
                        <Text tag="p" className="bold">
                            {props.socialMediaText}
                        </Text>
                    )}
                    <SocialMediaIconLinks
                        {...props.socialMedia}
                        className={cn(props.socialMedia.className, styles.socialMediaIcons)}
                    />
                </div>
            )}

            {props.customComponent && props.customComponent}
        </div>
    );
}
