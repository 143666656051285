import MediaWithText from './MediaWithText';
import type { MediaWithTextGroupProps, Variant } from './interfaces';
import styles from './mediawithtext.module.scss';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import { Cols, ColBreakpoints } from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid';

export default function MediaWithTextGroup({
    isTwelveColumn = false,
    variant,
    items,
    featured,
}: MediaWithTextGroupProps): JSX.Element {
    function calculateColumns(variant: Variant, isTwelveColumn: boolean): Cols {
        switch (variant) {
            case '2inRow':
                return isTwelveColumn ? 6 : 5;
            case '3inRow':
                return 4;
            case '4inRow':
                return 3;
            default:
                return 6;
        }
    }

    function calculateStart(variant: Variant, isTwelveColumn: boolean, i: number): ColBreakpoints {
        if (!isTwelveColumn && variant === '2inRow' && i % 2 === 0) {
            return { xs: 1, lg: 2 };
        } else {
            return { xs: undefined };
        }
    }

    return (
        <Grid container standardMoleculeSpacing standardAtomSpacing>
            {items.map((item, i) => (
                <Grid
                    key={i}
                    col={{ xs: 12, md: 6, lg: calculateColumns(variant, isTwelveColumn) }}
                    start={calculateStart(variant, isTwelveColumn, i)}
                    className={featured ? styles.mediaRowFeatured : ''}
                >
                    <MediaWithText {...item} featured={featured} />
                </Grid>
            ))}
        </Grid>
    );
}
