import cn from 'classnames';
import styles from './marketinglist.module.scss';
import IconCheckmarkSm from '@/assets/icons/icon-checkmark-sm.svg';
import { useId } from 'react';
import ShowMore from 'de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export type BulletTypes = 'icon' | 'checkmark' | 'none';
export type ListStyles = 'small' | 'regular';

interface ListItem {
    iconSrc?: string;
    shortDescription?: React.ReactNode;
    mediumDescription?: React.ReactNode;
    moreInfo?: React.ReactNode;
}

interface MarketingListProps {
    items: ListItem[];
    bulletType?: BulletTypes;
    className?: string;
    whiteFilter?: boolean;
    visibleItems?: number;
    listStyle?: ListStyles;
    checkmarkColor?: string;
}

export default function MarketingList({
    items,
    bulletType = 'checkmark',
    className,
    whiteFilter = false,
    visibleItems = 2,
    listStyle = 'regular',
    checkmarkColor,
}: MarketingListProps): JSX.Element {
    const contentId = useId();

    const classNames = cn(styles.marketingList, className, {
        [styles.checkmarkBullet]: bulletType === 'checkmark',
        [styles.iconBullet]: bulletType === 'icon',
        [styles.listSmall]: listStyle === 'small',
        [styles.whiteFilter]: whiteFilter,
    });

    const renderListItems = () => {
        return items.map((item, i) => (
            <li key={i} className={i >= visibleItems ? styles.itemToHide : ''}>
                {bulletType === 'checkmark' ? (
                    <IconCheckmarkSm style={{ stroke: checkmarkColor || '' }} />
                ) : bulletType === 'icon' && item.iconSrc ? (
                    <ImageWrapper optimizedWidth={40} optimizedHeight={40} alt="" src={item.iconSrc} />
                ) : null}

                <div>
                    {item.shortDescription && <p className={styles.shortDescription}>{item.shortDescription}</p>}
                    {item.mediumDescription && <p className={styles.mediumDescription}>{item.mediumDescription}</p>}
                    {item.moreInfo && <p className={styles.moreInfo}>{item.moreInfo}</p>}
                </div>
            </li>
        ));
    };

    if (visibleItems && visibleItems >= items.length) {
        return <ul className={classNames}>{renderListItems()}</ul>;
    }

    return (
        <ShowMore
            showText="Show More"
            hideText="Show Less"
            color={whiteFilter ? 'var(--white)' : 'var(--blue-secondary)'}
            contentClassName={styles.hideItems}
            shownClassName={styles.showItems}
            className={styles.showMore}
            id={contentId}
        >
            <ul className={classNames}>{renderListItems()}</ul>
        </ShowMore>
    );
}
