'use client';
import styles from './trigger.module.scss';
import { useId, useState, useEffect, useCallback, ReactNode } from 'react';
import useYmm from '@/hooks/useYmm';
import IconDropDown from '@/assets/icons/icon-dropdown.svg';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';

interface Props {
    onClick: () => void;
    variant?: 'standard' | 'simple' | 'static';
    children?: ReactNode;
    className?: string;
    ymmType?: 'infotainment' | 'channelGuide';
    prefixString?: ReactNode;
    initialText?: ReactNode;
    noHorizontalPadding?: boolean;
}

export function Trigger(props: Props): JSX.Element {
    const cookieValues = useYmm();
    const userSelected = cookieValues.sxm_radio.length || cookieValues.ymm_bar.length;
    const triggerId = useId();
    const [prefixString, setPrefixString] = useState(props.prefixString ?? 'Showing channels for ');
    const [barText, setBarText] = useState<ReactNode | null>();

    const generateText = useCallback(() => {
        const text: ReactNode[] = [];

        const car = cookieValues.sxm_radio.split('|');
        if (car.length > 1) {
            text.push(<b key={car[1]}>{`${car[1]} ${car[2]} ${car[3]}`}</b>);
        }

        cookieValues.ymm_bar.split('|').forEach((radio, i) => {
            if (radio.indexOf('radio') >= 0) {
                text.push(<b key={i}>{radio.split('=')[1]}</b>);
            } else if (radio.indexOf('stream') >= 0) {
                text.push(<b key={i}>streaming online</b>);
            }
        });

        if (!text.length && props.initialText) {
            text.push(props.initialText);
        } else if (!text.length) {
            text.push(
                <>
                    <b>listening in a car</b> and <b>streaming online</b>
                </>,
            );
        }

        setBarText(text.map((element) => element).reduce((prev, curr) => [prev, ' and ', curr]));
    }, [cookieValues.sxm_radio, cookieValues.ymm_bar, props.initialText]);

    useEffect(() => {
        if (userSelected) {
            setPrefixString((prevString) => {
                if (!JSON.stringify(prevString).includes('my')) {
                    return `${prevString} my `;
                } else {
                    return prevString;
                }
            });
        }

        generateText();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateText]);

    if (!barText) {
        return <div className={styles.container}>&nbsp;</div>;
    }

    if (props.variant === 'simple') {
        return (
            <Grid container noPadding={props.noHorizontalPadding}>
                <Grid col={{ sm: 12 }}>
                    <div className={`${styles.container} ${props.className}`} data-swiftype-index="false">
                        <span id={`${triggerId}-desc`}>
                            {props.ymmType === 'channelGuide' ? props.prefixString : prefixString}
                        </span>
                        <button
                            className={styles.button}
                            aria-describedby={`${triggerId}-desc`}
                            aria-haspopup={true}
                            onClick={props.onClick}
                            data-cy="molecules-ymm-trigger-button-simple"
                        >
                            {barText}
                            {props.ymmType === 'channelGuide' && <IconDropDown className={styles.iconStyle} />}
                        </button>
                        {props.children}
                    </div>
                </Grid>
            </Grid>
        );
    }

    if (props.variant === 'static') {
        // Used for now in the Index in the EmptyTabsMessage for Schedule and Shows type.
        return (
            <Grid container noPadding={props.noHorizontalPadding}>
                <Grid col={{ sm: 12 }}>
                    <div className={`${styles.container} ${props.className}`}>
                        <button
                            className={styles.button}
                            aria-describedby={`${triggerId}-desc`}
                            aria-haspopup={true}
                            onClick={props.onClick}
                            data-cy="molecules-ymm-trigger-button-static"
                        >
                            {props.initialText}
                        </button>
                        {props.children}
                    </div>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container noPadding={props.noHorizontalPadding}>
            <Grid col={{ sm: 12 }}>
                <div className={`${styles.container} ${props.className}`}>
                    <span id={`${triggerId}-editDesc`}>
                        {prefixString}
                        {barText}.&nbsp;
                    </span>
                    <button
                        className={styles.button}
                        aria-describedby={`${triggerId}-editDesc`}
                        aria-haspopup={true}
                        onClick={props.onClick}
                        data-cy="molecules-ymm-trigger-button-default"
                    >
                        Edit
                    </button>
                    {props.children}
                </div>
            </Grid>
        </Grid>
    );
}
