export const PULSE = Object.freeze({
    stellaAltText: 'stella dog icon',
    blackLogoAltText: 'black sirius logo',
    blueLogoAltText: 'blue sirius logo',
    blueArrowAltText: 'blue arrow on link',
    whiteArrowAltText: 'white arrow on link',
    blackArrowAltText: 'black arrow on link',
    backgroundAltText: 'background image',
    carDashboardAltText: 'car dashboard icon',
    mobileAltText: 'mobile phone icon',
    firstCtaText: 'LISTEN NOW',
    appLinkText: 'Listen on the app now',
    appLinkUrl: 'https://www.siriusxm.com/SAL',
    carTitle: 'In your new car',
    carText: 'Try SiriusXM on your car radio. Enjoy the ride with over 165 channels.',
    appTitle: 'On the SiriusXM app',
    appText: 'Discover 260+ app only channels and curate your library of content.',
    emailPlaceholder: 'e.g. joe@email.com',
    emailToastMessage: 'Check your inbox',
    emailToastBody: 'If your email address is connected to an active trial, you will receive an email message. Please check your spam.',
    emailInputId: 'email-input',
    emailErrorTitle: 'Invalid Email Address',
    emailErrorMessage: 'Please enter a valid email address (e.g., joe@email.com)',
    emailFieldLabel: 'Email',
    emailCloseLinkText: 'Not right now',
});