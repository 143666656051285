export function getGenreList(mainTab: string) {

    const genreList = {
        Music: [
            { value: 'All', label: 'All Music' },
            { value: 'Pop', label: 'Pop' },
            { value: 'Rock', label: 'Rock' },
            { value: 'Hip-Hop/R&B', label: 'Hip-Hop/R&B' },
            { value: 'Dance/Electronic', label: 'Dance/Electronic' },
            { value: 'Country', label: 'Country' },
            { value: 'Jazz/Standards/Classical', label: 'Jazz/Standards/Classical' },
            { value: 'Christian', label: 'Christian' },
            { value: 'Latino', label: 'Latino' },
            { value: 'Family', label: 'Family' },
            { value: 'Top 100', label: 'Top 100' },
            { value: 'New Music Discovery', label: 'New Music Discovery' },
            { value: 'Music Mash', label: 'Music Mash' },
            { value: "Today's Hits", label: "Today's Hits" },
            { value: 'Party', label: 'Party' },
            { value: 'Workout', label: 'Workout' },
            { value: 'The 80s Decade', label: 'The 80s Decade' },
            { value: 'Cool Down', label: 'Cool Down' },
            { value: 'The 70s Decade', label: 'The 70s Decade' },
            { value: 'BBQ', label: 'BBQ' },
            { value: 'The 90s Decade', label: 'The 90s Decade' },
            { value: 'Instrumentals', label: 'Instrumentals' },
            { value: 'Just Music', label: 'Just Music' },
            { value: 'Deep Cuts', label: 'Deep Cuts' },
            { value: 'Relax', label: 'Relax' },
            { value: 'Global', label: 'Global' },
            { value: 'AllX', label: 'All Xtra Channels' },
        ],
        Sports: [
            { value: 'All', label: 'All Sports' },
            { value: 'Sports', label: 'Sports Talk' },
            { value: 'NFL Play-by-Play', label: 'NFL Play-by-Play' },
            { value: 'MLB Play-by-Play', label: 'MLB Play-by-Play' },
            { value: 'NBA Play-by-Play', label: 'NBA Play-by-Play' },
            { value: 'NHL Play-by-Play', label: 'NHL Play-by-Play' },
            { value: 'EPL Play-by-Play', label: 'EPL Play-by-Play' },
            { value: 'Sports Play-by-Play', label: 'Sports Play-by-Play' },
        ],
        Talk: [
            { value: 'All', label: 'All Talk' },
            { value: 'Entertainment', label: 'Entertainment' },
            { value: 'Religion', label: 'Religion' },
            { value: 'Canadian', label: 'Canadian' },
            { value: 'Comedy', label: 'Comedy' },
            { value: 'Howard Stern', label: 'Howard Stern' },
        ],
        News: [
            { value: 'All', label: 'All News' },
            { value: 'News/Public Radio', label: 'News/Public Radio' },
            { value: 'Politics/Issues', label: 'Politics/Issues' },
        ]
    }

    return genreList[mainTab as keyof typeof genreList]
}