import { Fragment, useState, useRef } from 'react';
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useMediaQuery } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import Button from 'de-sxm-reactlib-2/dist/components/atoms/Button/Button';
import styles from './channelHeader.module.scss';

const { channelSelectorWrapper, buttonStyles } = styles;

type ChannelButton = { title: string };

interface ChannelHeaderProps {
    channelButtons: ChannelButton[];
    onClick: (arg: string) => void;
}

export default function ChannelHeader({ channelButtons, onClick }: ChannelHeaderProps) {
    const viewportIsMd = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const [activeButton, setActiveButton] = useState(0);
    const splideRef = useRef<Splide | null>(null);

    function handleButtonClick(index: number) {
        splideRef.current?.splide?.go(index);
        onClick(channelButtons[index].title);
        setActiveButton(index);
    }

    const channelTabButtons = channelButtons.map((button, index) => {
        return {
            content: (
                <Button
                    key={button.title}
                    onClick={() => handleButtonClick(index)}
                    variant="custom"
                    color={index === activeButton ? '#222222' : '#f7f7f7'}
                    disableHoverGrow
                    type="button"
                    className={buttonStyles}
                >
                    {button.title}
                </Button>
            ),
        };
    });

    if (viewportIsMd) {
        return (
            <div className={channelSelectorWrapper}>
                {channelTabButtons.map((button, index) => (
                    <Fragment key={index}>{button.content}</Fragment>
                ))}
            </div>
        );
    } else {
        const sliderOptions: Options = {
            rewind: false,
            gap: '8px',
            pagination: false,
            arrows: false,
            drag: 'free',
            autoWidth: true,
            padding: '16px',
            focusableNodes: 'button',
        };

        return (
            <Splide options={sliderOptions} hasTrack={false} ref={splideRef}>
                <SplideTrack>
                    {channelTabButtons.map((slide, index) => {
                        return <SplideSlide key={index}>{slide.content}</SplideSlide>;
                    })}
                </SplideTrack>
            </Splide>
        );
    }
}
