'use client';
import NavHeader from 'de-sxm-reactlib-2/dist/components/organisms/NavHeader/NavHeader';
import { NavHeaderProps } from 'de-sxm-reactlib-2/dist/components/organisms/NavHeader/NavHeader.type';
import NavSearch from './NavSearch';
import { useState } from 'react';

export default function ClientNavigationHeader({
    logo,
    logoUrl,
    navLeftItems,
    helpCenterItem,
    theme,
    appdownloadItem,
    search,
    account,
    avoidNavScroll,
    supportTitle,
    navRightCtas,
    navTheme,
    pageHasYmm,
    searchSuggestions,
    searchSuggestionsLabel,
    searchQuickLinks,
    announcementBanners,
    headerId,
    quickLinksHeader,
    overlap=true,
}: NavHeaderProps) {
    const [navSearchOpen, setNavSearchOpen] = useState(false);

    const searchBar = (
        <NavSearch
            open={navSearchOpen}
            searchTerm=""
            minSearchLength={3}
            userTypingDelay={250}
            searchSuggestions={searchSuggestions}
            searchSuggestionsLabel={searchSuggestionsLabel}
            searchQuickLinks={searchQuickLinks}
            onCancelClick={() => setNavSearchOpen(false)}
            quickLinksHeader={quickLinksHeader}
        />
    );

    return (
        <NavHeader
            logo={logo}
            logoUrl={logoUrl}
            navLeftItems={navLeftItems}
            helpCenterItem={helpCenterItem}
            theme={theme}
            appdownloadItem={appdownloadItem}
            searchBar={searchBar}
            searchOpen={navSearchOpen}
            onSearchClose={() => setNavSearchOpen(false)}
            onSearchOpen={() => setNavSearchOpen(true)}
            search={search}
            account={account}
            avoidNavScroll={avoidNavScroll}
            supportTitle={supportTitle}
            navRightCtas={navRightCtas}
            navTheme={navTheme}
            pageHasYmm={pageHasYmm}
            announcementBanners={announcementBanners}
            headerId={headerId}
            overlap={overlap}
        />
    );
}
