import styles from './planlabel.module.scss';

interface PlanLabelProps {
    className: string;
    text: string;
}

export default function PlanLabel(props: PlanLabelProps): JSX.Element {
    return <span className={`${styles.packageLabel} ${props.className}`}>{props.text}</span>;
}
