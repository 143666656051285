'use client';
import RenderAsset from '@/components/RenderAsset';
import { YMM } from '@/components/molecules/YMM';
import { YMMData } from '@/components/molecules/YMM/Form/interfaces';
import { getYMMCookies, setYMMCookies } from '@/packages/sxm/ymm';
import { Platform } from '@/packages/sxm/ymm/types';
import { useEffect, useState } from 'react';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import StyledContainer from 'de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';

interface Props {
    assets: SiteAsset[];
}

type RefreshState = 'disable' | 'refresh';

export default function VehicleFeatures({ assets }: Props): React.ReactNode {
    const [refresh, setRefresh] = useState<RefreshState>('disable');
    const [ymmData, setYmmData] = useState<YMMData>({
        year: '',
        make: '',
        model: '',
        id: '',
    });

    useEffect(() => {
        const ymmCookies = getYMMCookies();
        const carData = ymmCookies.sxm_radio.split('|');

        if (carData[1]) {
            setRefresh('disable');

            setYmmData({ year: carData[1], make: carData[2], model: carData[3], id: carData[4] });
        }
    }, []);

    useEffect(() => {
        if (refresh === 'refresh') {
            setRefresh('disable');
            window.location.reload();
        }
    }, [refresh]);

    function onCarReady(ymm: YMMData) {
        // Since the capabilities data might be from the previews vehicle or undefined we want to be sure the capabilities are from the same vehicle before updating.
        if (window && ymm && ymm.id === ymm.capabilities?.ymmComboId) {
            let platform: Platform = 'siriusxm';
            if (ymm && ymm.capabilities) {
                if (ymm.capabilities.siriusRadio) {
                    platform = 'sirius';
                } else if (ymm.capabilities.xmRadio) {
                    platform = 'xm';
                }
            }

            setYMMCookies({
                sxm_radio: `YMM|${ymm.year}|${ymm.make}|${ymm.model}|${ymm.id}`,
                sxm_platform: platform,
                inft_ymm: ymm.capabilities?.infotainment.join('|') || '',
                ymm_bar: '',
                roadblockbypass: '',
            });

            if (
                ymm.year !== ymmData.year ||
                ymm.make !== ymmData.make ||
                ymm.model !== ymmData.model ||
                ymm.id !== ymmData.id
            ) {
                setYmmData({
                    year: ymm.year,
                    make: ymm.make,
                    model: ymm.model,
                    id: ymm.id,
                });
                setRefresh('refresh');
            }
        }
    }

    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <StyledContainer paddingBottom={24}>
                    <YMM.Form theme="evenly-spaced" onCarReady={onCarReady} reset={true} />
                </StyledContainer>
                {ymmData.year && ymmData.make && (
                    <Text tag="p" variant="h5" dataCy="contentful-asset-group-vehicle-features-ymm-text">
                        {ymmData.year} {ymmData.make} {ymmData.model}
                    </Text>
                )}
                <Text tag="p" variant="bold">
                    The following services may be available for the vehicle listed above
                </Text>
                {assets.map((asset) => (
                    <RenderAsset key={asset.cms_id} asset={asset} />
                ))}
            </Grid>
        </Grid>
    );
}
