import globalProperties from '../../../properties/global';

interface Args {
    src: string;
    width?: number;
    height?: number;
    skipOptimization?: boolean;
}

interface ImgoPayload {
    key: string;
    edits: (FormatEdit | ResizeEdit)[];
}
interface FormatEdit {
    format: { type: string };
}
interface ResizeEdit {
    resize: {
        width: number;
        height: number;
    };
}
const optimizationAllowedFileTypes = ['jpeg', 'jpg', 'png'];

export default function ImageOptimization({ src, width, height, skipOptimization }: Args): string {
    if (!src) {
        return src;
    }

    let domain = '';
    let path = src;
    if(src.indexOf('siriusxm.com') > 0) {
        const parts = src.split('siriusxm.com');
        domain = `${parts[0]}siriusxm.com`;
        path = parts[1]
    }

    function optimizeImage() {
        if (path.charAt(0) === '/') {
            path = path.substring(1);
        }

        const json: ImgoPayload = {
            key: path,
            edits: [{ format: { type: 'webp' } }],
        };

        if (width && height && width > 0 && height > 0) {
            json.edits.push({ resize: { width: width, height: height } });
        }

        const base64 = btoa(JSON.stringify(json));
        return `${domain}/imgo/${base64}`;
    }

    const enableOptimization = globalProperties.optimizeImage && !skipOptimization;
    if ((enableOptimization || path.includes('playerimage')) && !path.includes('https') && !path.includes('/vod/image')) {
        path = path.split('?')[0];
        const filetype = path.split('.')?.pop();
        if (filetype && optimizationAllowedFileTypes.includes(filetype.toLowerCase())) {
            src = optimizeImage();
        }
    }

    return src;
}
