import { FeatureGroup as FeatureGroupUi, FeatureGroupItemProps } from '@/components/molecules/FeatureGroup';
import { RenderRTE } from '@/components/RenderRTE';

export default function FeatureGroup(assetGroup: SiteAssetGroup): JSX.Element {
    const asW2 = assetGroup?.pageContext?.properties?.web2styles === 'true';
    const vehicleAvailabilityPage = assetGroup.presentation?.presentations['Feature']?.template === 'VehicleFeatures';
    const features: SiteFeature[] = assetGroup.fields.assets as SiteFeature[];
    function getFeatureGroupItemProp(feature: SiteFeature): FeatureGroupItemProps {
        let link;
        if (feature.fields.link) {
            const target = feature.fields.link.fields.link_target;
            link = {
                href: feature.fields.link.fields.href,
                link_target: target,
                link_text: feature.fields.link.fields.link_text,
            };
        }
        const featureDescription = feature.fields.feature_description ? (
            <RenderRTE contentfulDoc={feature.fields.feature_description} pToSpan={true} asW2={asW2} />
        ) : (
            <></>
        );
        const featureTitle = feature.fields.short_description ? (
            <RenderRTE contentfulDoc={feature.fields.short_description} pToSpan={true} asW2={asW2} />
        ) : (
            <></>
        );
        return {
            image: {
                alt: feature.fields.logo?.altText || '',
                src: feature.fields.logo?.url || '',
            },
            itemText: featureDescription,
            itemTitle: featureTitle,
            link: link,
        };
    }

    const items: FeatureGroupItemProps[] = [];

    features.forEach((feature) => {
        items.push(getFeatureGroupItemProp(feature));
    });

    return <FeatureGroupUi items={items} bottomBorder={vehicleAvailabilityPage} fullWidth={vehicleAvailabilityPage} />;
}
