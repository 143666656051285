import { responsiveBreakpointsPx } from '@/constants';

export interface ContentSliderOptionsProps {
    fixedWidthXl?: string | number;
    fixedWidthLg?: string | number;
    fixedWidthMd?: string | number;
    arrowsDesktop?: boolean;
}

export function contentCardSliderOptions({
    fixedWidthXl,
    fixedWidthLg,
    fixedWidthMd,
    arrowsDesktop = false,
}: ContentSliderOptionsProps) {
    return {
        rewind: false,
        gap: '16px',
        pagination: false,
        arrows: arrowsDesktop,
        drag: true,
        focusableNodes: 'button, textarea, input, select, iframe',
        fixedWidth: fixedWidthXl,
        perMove: 1,
        padding: 0,
        type: 'slide',
        breakpoints: {
            [responsiveBreakpointsPx.lg]: {
                gap: '16px',
                pagination: false,
                fixedWidth: fixedWidthLg,
                perMove: 1,
                arrows: false,
                drag: true,
                padding: 0,
                type: 'slide',
            },
            [responsiveBreakpointsPx.md]: {
                gap: '16px',
                pagination: false,
                perMove: 1,
                fixedWidth: fixedWidthMd,
                arrows: false,
                padding: 0,
                drag: true,
                type: 'slide',
            },
        },
    };
}

export const StandardMoleculeSliderOptions = {
    rewind: false,
    gap: '16px',
    pagination: false,
    arrows: false,
    drag: false,
    focusableNodes: 'button, textarea, input, select, iframe',
    perPage: 3,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 2,
            arrows: false,
            drag: true,
            padding: {
                left: '16px',
                right: '24px',
                toop: '24px',
                bottom: '24px',
            },
            type: 'slide',
        },
        [responsiveBreakpointsPx.md]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 1,
            arrows: false,
            padding: {
                left: '16px',
                right: '24px',
                toop: '24px',
                bottom: '24px',
            },
            drag: true,
            type: 'slide',
        },
    },
    lazyLoad: true,
};

export const EntityTileGridSliderOptions = {
    rewind: false,
    gap: '16px',
    pagination: false,
    arrows: false,
    drag: false,
    focusableNodes: 'button, textarea, input, select, iframe',
    perPage: 3,
    breakpoints: {
        // We subtract 1 from these desktop-first breakpoints so the mobile-first SCSS breaks on exactly the same width:
        [responsiveBreakpointsPx.lg - 1]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 2,
            arrows: false,
            drag: true,
            padding: {
                left: '24px',
                right: '24px',
            },
            type: 'slide',
        },
        [responsiveBreakpointsPx.md - 1]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 1,
            arrows: false,
            padding: {
                left: '16px',
                right: '24px',
            },
            drag: true,
            type: 'slide',
        },
    },
    lazyLoad: true,
};
