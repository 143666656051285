import { useMemo } from 'react';
import cn from 'classnames';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import Video from 'de-sxm-reactlib/dist/components/atoms/Media/Video/Video';
import LinkWrapperIfHref from 'de-sxm-reactlib/dist/components/atoms/LinkWrapperIfHref/LinkWrapperIfHref';
import { SafeHtml } from '@/components/atoms/Display';
import { MediaWithTextImage } from './MediaWithTextImage';
import type { MediaWithTextProps } from './interfaces';
import styles from './mediawithtext.module.scss';

export default function MediaWithText(props: MediaWithTextProps): JSX.Element {
    const { image, video, headline, subHeader, body, imageDownload, featured, headlineAsLink, link } = props;

    const mediaComponent = useMemo(() => {
        const imageLink = imageDownload && image?.source ? { ...link, href: image.source } : link;

        if (image) {
            return (
                <MediaWithTextImage
                    imageIsLogo={props.imageIsLogo}
                    imageOverlay={props.imageOverlay}
                    imageSize={props.imageSize}
                    image={image}
                    mediaBackgroundColor={props.mediaBackgroundColor}
                    link={imageLink}
                    downloadable={imageDownload}
                />
            );
        } else if (video) {
            return <Video {...video} />;
        }
        return null;
    }, [image, video, link, imageDownload, props]);

    const headlineClasses = cn(styles.headline, 'large-copy', 'bold', {
        [styles.headlineAsLink]: !!link?.href && headlineAsLink,
    });

    return (
        <>
            <div className={cn(styles.mediaElement, { [styles.featured]: featured })}>{mediaComponent}</div>
            <div className={cn({ [styles.mediaContent]: featured })}>
                {(headline || subHeader) && (
                    <LinkWrapperIfHref link={(headlineAsLink && link) || undefined} className={headlineClasses}>
                        {headline && <SafeHtml print={headline as string} />}
                        {subHeader && (
                            <Text tag="p" className={styles.subHeader}>
                                {subHeader}
                            </Text>
                        )}
                    </LinkWrapperIfHref>
                )}
                {body && (
                    <Text tag="div" className={styles.bodyText} dataCy="molecules-media-with-text-body">
                        {body}
                    </Text>
                )}
            </div>
        </>
    );
}
