import cn from 'classnames';
import PlanCard, { PlanCardProps } from '../PlanCard/PlanCard';
import styles from './plancardgroup.module.scss';

interface PlanCardGroupProps {
    planCards: PlanCardProps[];
    isInHero?: boolean;
}

export default function PlanCardGroup({ planCards, isInHero = false }: PlanCardGroupProps): JSX.Element {
    const classNames = cn(styles.planCards, {
        [styles.inHero]: isInHero,
    });

    return (
        <div className={classNames}>
            {planCards.map((planCardArgs, index) => (
                <PlanCard key={index} {...planCardArgs} />
            ))}
        </div>
    );
}
