'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useState } from 'react';
import { sourceDataToPlanRows } from '../../data/dataUtils';
import { FootnoteReferenceNumbers, SourcePackageData, TooltipData } from '../../data/interfaces';
import { PlanGrid } from '../PlanGrid';
import styles from './planselect.module.scss';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const ShadowBox = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/ShadowBox/ShadowBox'));
const Tooltip = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Tooltip/Tooltip'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const SelectGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/SelectGroup'));

type DataObjectTypes = SourcePackageData | TooltipData;

export interface PlanSelectProps {
    sectionHeader?: string;
    sectionSubHeader?: string;
    rowNames: string[];
    data: DataObjectTypes[];
    footnoteReferenceNumbers?: FootnoteReferenceNumbers;
    featureBlacklist?: string[];
    simplifyRowDescriptions?: boolean;
    rightColumnOverride?: SourcePackageData;
}

export default function PlanSelect({
    sectionHeader,
    sectionSubHeader,
    rowNames,
    data,
    footnoteReferenceNumbers,
    featureBlacklist = [],
    simplifyRowDescriptions,
    rightColumnOverride,
}: PlanSelectProps): JSX.Element {
    const [leftPlan, setLeftPlan] = useState(data[0]);
    const [rightPlan, setRightPlan] = useState(rightColumnOverride ?? data[1] ?? data[0]);
    const [tooltipData] = data.filter((element) => Object.keys(element)[0] === 'tooltipData') as TooltipData[];
    const dataWithoutTooltip = data.filter(
        (element) => Object.keys(element)[0] !== 'tooltipData',
    ) as SourcePackageData[];

    const plans = sourceDataToPlanRows(
        rowNames,
        [leftPlan, rightPlan] as SourcePackageData[],
        simplifyRowDescriptions,
        tooltipData,
        footnoteReferenceNumbers,
        featureBlacklist,
    );

    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <>
                    {sectionHeader && (
                        <div className={styles.sectionHeader}>
                            <h3>{sectionHeader}</h3>
                            {sectionSubHeader && <p>{sectionSubHeader}</p>}
                        </div>
                    )}
                    <ShadowBox targetClass={cn(styles.contentWrapper, styles.tableHeaderWrapper)} roundedBorder>
                        <h4 className={styles.tableHeader} id="package-feature">
                            Package Features
                            <Tooltip
                                ariaLabel="Learn more about package features"
                                position="right"
                                contentClassName={styles.tooltipContent}
                            >
                                The availability and display of service features and SiriusXM Audio varies by hardware
                                provider.
                            </Tooltip>
                        </h4>
                        <div className={styles.selectWrapper}>
                            <SelectGroup className={styles.selectGroup}>
                                <Select<SourcePackageData>
                                    type="bar"
                                    theme="whiteblue"
                                    label={(leftPlan as SourcePackageData).packageName}
                                    name="leftPlan"
                                    value={(leftPlan as SourcePackageData).packageName}
                                    className={styles.selectColumn}
                                    onChange={(_, { data: newLeftPlanData }) => {
                                        return newLeftPlanData && setLeftPlan(newLeftPlanData);
                                    }}
                                    ariaLabel={`Select First Package ${(leftPlan as SourcePackageData).packageName} menu`}
                                >
                                    <OptionGroup>
                                        {dataWithoutTooltip.map((item, index) => (
                                            <Option
                                                value={item.packageName}
                                                object={item}
                                                className={styles.option}
                                                key={index}
                                            >
                                                {item.packageName}
                                            </Option>
                                        ))}
                                    </OptionGroup>
                                </Select>

                                {rightColumnOverride ? (
                                    <div className={styles.overrideText}>
                                        {rightColumnOverride.packageName}
                                        {footnoteReferenceNumbers?.plans?.[rightColumnOverride.packageName] && (
                                            <sup>{footnoteReferenceNumbers.plans[rightColumnOverride.packageName]}</sup>
                                        )}
                                    </div>
                                ) : (
                                    <Select<SourcePackageData>
                                        type="bar"
                                        theme="whiteblue"
                                        label={(rightPlan as SourcePackageData).packageName}
                                        name="rightPlan"
                                        value={(rightPlan as SourcePackageData).packageName}
                                        className={styles.selectColumn}
                                        onChange={(_, { data: newRightPlanData }) => {
                                            return newRightPlanData && setRightPlan(newRightPlanData);
                                        }}
                                        ariaLabel={`Select Second Package ${(rightPlan as SourcePackageData).packageName} menu`}
                                    >
                                        <OptionGroup>
                                            {dataWithoutTooltip.map((item, index) => (
                                                <Option value={item.packageName} object={item} key={index}>
                                                    {item.packageName}
                                                </Option>
                                            ))}
                                        </OptionGroup>
                                    </Select>
                                )}
                            </SelectGroup>
                        </div>
                    </ShadowBox>

                    <ShadowBox targetClass={cn(styles.contentWrapper, styles.tableWrapper)} roundedBorder>
                        <PlanGrid
                            plansData={plans}
                            id={'streaming-panel'}
                            ariaLabelledby={'streaming-tab'}
                            activeTab={0}
                            showEmptyCellIcon
                            hideHeader
                            alignment="left"
                            variant="spacious"
                        />
                    </ShadowBox>
                </>
            </Grid>
        </Grid>
    );
}
