'use client';
import { WidgetHero } from '@/components/molecules/Hero';
import { DynamicTable } from '@/components/molecules/DynamicTable';
import { SportsCard } from '@/components/molecules/SportsCard';
import { Team } from '@/components/molecules/SportsCard/SportsCard';
import IconCar from '@/assets/icons/icon-car.svg';
import IconDevices from '@/assets/icons/icon-devices.svg';
import { DynamicTableRow } from '@/components/molecules/DynamicTable/DynamicTable';
import styles from './hero.module.scss';
import { Link } from '@/components/atoms/Link';
import globalProperties from '../../../../properties/global';
import { useEffect, useRef, useState } from 'react';
import Script from 'next/script';
import { TeamHeroGameData } from './TeamHeroGameData';
import Button from 'de-sxm-reactlib/dist/components/atoms/Button/Button';

interface Props {
    breadcrumbs?: Link[];
    gameData?: TeamHeroGameData;
    teamCity?: string;
    teamColor?: string;
    teamName?: string;
    teamLogo?: AEMImage;
    listenLiveLink?: string;
}

export default function TeamHero({
    breadcrumbs,
    gameData,
    teamCity,
    teamColor,
    teamName,
    teamLogo,
    listenLiveLink,
}: Props) {
    const [calReplyLoaded, setCalReplyLoaded] = useState(false);
    const calReplyBtnRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (calReplyLoaded && calReplyBtnRef.current) {
            const browserWindow = window as BrowserWindow;
            browserWindow.__CalReplyWidgetLoader.loadCustomButton(calReplyBtnRef.current);
        }
    }, [calReplyLoaded]);

    function renderChannelLink(
        deepLink: string | undefined,
        channelNumber: string | number | undefined,
        ariaLabel: string,
    ) {
        if (deepLink) {
            return (
                <Link href={deepLink} target="_blank" aria-label={ariaLabel} className={styles.link}>
                    {channelNumber}
                </Link>
            );
        }
        return channelNumber;
    }

    const iconStyles = {
        width: '28px',
        height: '28px',
        fill: 'var(--black)',
    };

    const moreWays: DynamicTableRow[] = [];

    let awayTeam: Team, homeTeam: Team;
    let teamCard;

    if (gameData) {
        awayTeam = {
            name: gameData.awayTeam || '',
            city: gameData.awayCity || '',
            link: {
                href: gameData.awayUrl,
            },
            logo: {
                source: gameData.awayLogo?.url || '',
                altText: '',
                width: 56,
                height: 56,
            },
            channel: String(gameData.awayStream || gameData.nationalStream || ''),
            isOnline:
                !(gameData.awayStream || gameData.nationalStream) &&
                !!(gameData.awayInternetStream || gameData.nationalInternetStream),
        };
        homeTeam = {
            name: gameData.homeTeam || '',
            city: gameData.homeCity || '',
            link: {
                href: gameData.homeUrl,
            },
            logo: {
                source: gameData.homeLogo?.url || '',
                altText: '',
                width: 56,
                height: 56,
            },
            channel: String(gameData.homeStream || gameData.nationalStream || ''),
            isOnline:
                !(gameData.homeStream || gameData.nationalStream) &&
                !!(gameData.homeInternetStream || gameData.nationalInternetStream),
        };

        if (gameData.awayStream || gameData.awayInternetStream) {
            const ariaLabel = `Listen to ${gameData.awayTeamCity} @ ${gameData.homeTeamCity} on away channel ${gameData.awayInternetStream}`;
            moreWays.push({
                rowLabel: 'Away',
                items: [
                    gameData.awayStream || '-',
                    renderChannelLink(gameData.awayStreamLink, gameData.awayInternetStream, ariaLabel),
                ],
            });
        }

        if (gameData.homeStream || gameData.homeInternetStream) {
            const ariaLabel = `Listen to ${gameData.awayTeamCity} @ ${gameData.homeTeamCity} on home channel ${gameData.homeInternetStream}`;
            moreWays.push({
                rowLabel: 'Home',
                items: [
                    gameData.homeStream || '-',
                    renderChannelLink(gameData.homeStreamLink, gameData.homeInternetStream, ariaLabel),
                ],
            });
        }

        if (gameData.nationalStream || gameData.nationalInternetStream) {
            const ariaLabel = `Listen to ${gameData.awayTeamCity} @ ${gameData.homeTeamCity} on national channel ${gameData.nationalInternetStream}`;
            moreWays.push({
                rowLabel: 'National',
                items: [
                    gameData.nationalStream || '-',
                    renderChannelLink(gameData.nationalStreamLink, gameData.nationalInternetStream, ariaLabel),
                ],
            });
        }

        if (gameData.spanishStream || gameData.spanishInternetStream) {
            const ariaLabel = `Listen to ${gameData.awayTeamCity} @ ${gameData.homeTeamCity} on spanish channel ${gameData.spanishInternetStream}`;
            moreWays.push({
                rowLabel: 'Spanish',
                items: [
                    gameData.spanishStream || '-',
                    renderChannelLink(gameData.spanishStreamLink, gameData.spanishInternetStream, ariaLabel),
                ],
            });
        }

        const moreWaysTable = (
            <DynamicTable
                columnLabels={[
                    <IconCar key={0} style={iconStyles} aria-label="In the car" />,
                    <IconDevices key={1} style={iconStyles} aria-label="On your device" />,
                ]}
                rows={moreWays}
            />
        );

        const calReplyBtn = (
            <Button
                ref={calReplyBtnRef}
                text="GET REMINDERS"
                type="button"
                theme="primary"
                href={`${globalProperties.sports.calreply.domain}/`}
                ariaLabel={`get reminders for ${gameData.awayCity} ${gameData.awayTeam} at ${gameData.homeCity} ${gameData.homeTeam}`}
                data={{
                    'data-calreply-binding': 'true',
                    'data-event-loaded': 'true',
                    'data-popup': 'lightbox',
                    'data-type': 'custombutton',
                }}
            />
        );

        teamCard = (
            <div className={styles.cardContainer}>
                <div className={styles.card}>
                    <div className={styles.sportsCardWrapper}>
                        <SportsCard
                            startTime={gameData.gameDate || ''}
                            endTime={gameData.endDateTime || ''}
                            cta={calReplyBtn}
                            matchup={[awayTeam, homeTeam]}
                            eventDateTime={new Date(gameData.gameDate || '')}
                            theme="Hero"
                            moreWaysTable={moreWaysTable}
                            homeTeamColor={gameData.homeColor}
                            awayTeamColor={gameData.awayColor}
                            listenLiveLink={{ href: listenLiveLink }}
                        />
                    </div>
                    <div className={styles.background}></div>
                </div>
            </div>
        );
    }

    return (
        <>
            <WidgetHero
                text={{
                    header: (
                        <>
                            {teamCity}
                            <br />
                            {teamName}
                        </>
                    ),
                    breadcrumbs: breadcrumbs,
                }}
                mobileOnlyForegroundImage={teamLogo as AEMImage}
                backgroundColor={teamColor}
                backgroundLogo={teamLogo?.url}
                textColor="var(--white)"
                mobileFlatBackground={false}
                widget={gameData ? teamCard : undefined}
                theme="Team"
            />
            <Script
                id="calreply-wjs"
                src="https://assets.calreplyapp.com/scripts/widget-loader.min.js"
                onLoad={() => setCalReplyLoaded(true)}
            />
        </>
    );
}
