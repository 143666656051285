import cn from 'classnames';
import { CSSProperties, ComponentProps, ReactNode } from 'react';
import { OfferIcons } from '@/components/atoms/OfferIcons';
import { PlanLabel } from '@/components/atoms/PlanLabel';
import { MarketingList } from '../MarketingList';
import Button from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import { ButtonTheme } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import ShadowBox from 'de-sxm-reactlib/dist/components/atoms/Display/ShadowBox/ShadowBox';
import type { ListStyles, BulletTypes } from '../MarketingList/MarketingList';
import styles from './plancard.module.scss';

export interface PlanCardProps {
    borderStyle?: 'line' | 'shadow';
    promoted?: boolean;
    promotedPlanLabel?: string;
    packageTitle: string;
    costDisplayStyle?: 'dynamic' | 'flat';
    packageCost: number;
    packageCostDuration: number;
    packageCostCopy: ReactNode | ReactNode[];
    packageCostSubtext: ReactNode | ReactNode[];
    showCostSeperator?: boolean;
    offerIcons?: ImageProps[];
    packageCopy: ReactNode | ReactNode[];
    packageFeatures: ComponentProps<typeof MarketingList>['items'];
    packageFeatureBodyMarginLeft?: number;
    ctaButtonText: string;
    ctaButtonTheme: ButtonTheme;
    ctaUrl: string;
    offerHeaderTextWithNoPrice: ReactNode | ReactNode[];
    isSingleCard?: boolean;
    isMobileShort?: boolean;
    bulletType?: BulletTypes;
    customStyles?: string;
    listStyle?: ListStyles;
    offerPromotionColor?: string;
    checkmarkColor?: string;
}

export default function PlanCard(props: PlanCardProps): JSX.Element {
    const hasButton = props.ctaUrl && props.ctaButtonText;
    const classNames = cn(styles.packageCard, {
        [styles.promoted]: props.promoted,
        [styles.singleCard]: props.isSingleCard,
        [styles.mobileShort]: props.isMobileShort,
    });
    const styleOverrides = {
        '--card-padding': props.borderStyle === 'shadow' ? '24px' : '32px',
        '--list-margin-bottom': '0',
    } as CSSProperties;

    const packageCardStyles = {
        '--border': props.borderStyle === 'shadow' ? 'none' : '1px solid',
    } as CSSProperties;

    return (
        <Wrapper borderStyle={props.borderStyle} customStyles={props.customStyles}>
            <div className={styles.cardWrapper} style={styleOverrides}>
                {props.promoted && props.promotedPlanLabel && (
                    <PlanLabel className={styles.floatTop} text={props.promotedPlanLabel} />
                )}
                <div className={classNames} style={packageCardStyles}>
                    <div className={styles.cardContent}>
                        <div className={styles.cardContentLeft}>
                            <div
                                className={cn(styles.packageContentTop, {
                                    [styles.seperator]: props.showCostSeperator,
                                })}
                            >
                                {props.packageTitle && (
                                    <Text tag="h3" variant="h6" dataCy="molecules-plan-card-package-title">
                                        {props.packageTitle}
                                    </Text>
                                )}

                                {typeof props.packageCost === 'number' &&
                                    typeof props.packageCostDuration === 'number' && (
                                        <PlanCost
                                            costDisplayStyle={props.costDisplayStyle}
                                            packageCost={props.packageCost}
                                            offerHeaderTextWithNoPrice={props.offerHeaderTextWithNoPrice}
                                            packageCostDuration={props.packageCostDuration}
                                            offerPromotionColor={props.offerPromotionColor}
                                        />
                                    )}

                                {props.packageCostCopy && (
                                    <div className="no-margin small-copy text-color-gray">{props.packageCostCopy}</div>
                                )}

                                {props.packageCostSubtext && (
                                    <p className={styles.packageCostSubtext}>{props.packageCostSubtext}</p>
                                )}
                            </div>

                            {props.offerIcons && (
                                <div className={styles.offerIcons}>
                                    <OfferIcons icons={props.offerIcons} desktopWhiteFilter={false} />
                                </div>
                            )}
                        </div>

                        <div className={styles.cardContentRight}>
                            {props.packageCopy && (
                                <p className={styles.packageCopy} data-cy="molecules-plan-card-package-copy">
                                    {props.packageCopy}
                                </p>
                            )}
                            {props.packageFeatures?.length && (
                                <MarketingList
                                    items={props.packageFeatures}
                                    visibleItems={props.packageFeatures.length}
                                    bulletType={props.bulletType}
                                    listStyle={props.listStyle ? props.listStyle : 'small'}
                                    checkmarkColor={props.checkmarkColor}
                                    className={cn({
                                        [styles.planCardMarketingList]: props.bulletType === 'icon',
                                        [styles.planCardCheckmarkBullet]: props.bulletType === 'checkmark',
                                    })}
                                />
                            )}
                        </div>
                    </div>

                    {hasButton && (
                        <Button
                            href={props.ctaUrl}
                            buttonSize="full-width"
                            theme={props.ctaButtonTheme}
                            type="button"
                            text={props.ctaButtonText}
                            className={styles.ctaButton}
                            dataCy="molecules-plan-card-cta-button"
                        />
                    )}
                </div>
            </div>
        </Wrapper>
    );
}

interface WrapperProps {
    children: ReactNode;
    borderStyle: PlanCardProps['borderStyle'];
    customStyles?: string;
}

function Wrapper({ children, borderStyle, customStyles }: WrapperProps) {
    if (borderStyle === 'shadow' && !customStyles) {
        return <ShadowBox targetClass={styles.wrapper}>{children}</ShadowBox>;
    }
    if (customStyles) {
        return <div className={customStyles}>{children}</div>;
    }

    return <div className={styles.wrapper}>{children}</div>;
}

function PlanCost({
    costDisplayStyle = 'dynamic',
    packageCost,
    offerHeaderTextWithNoPrice,
    packageCostDuration,
    offerPromotionColor,
}: {
    costDisplayStyle: PlanCardProps['costDisplayStyle'];
    packageCost: PlanCardProps['packageCost'];
    offerHeaderTextWithNoPrice: PlanCardProps['offerHeaderTextWithNoPrice'];
    packageCostDuration: PlanCardProps['packageCostDuration'];
    offerPromotionColor: PlanCardProps['offerPromotionColor'];
}) {
    return (
        <div className={styles.packageCostContainer}>
            <div>
                {costDisplayStyle === 'dynamic' ? (
                    <>
                        <div>
                            <Text
                                tag="span"
                                variant="h2"
                                className={styles.packageCost}
                                style={{ color: offerPromotionColor || '' }}
                            >
                                {packageCost === 0 ? 'Free' : `$${packageCost}`}
                            </Text>
                            <Text tag="span" className={cn(styles.packageCostDuration, 'black-weight')}>
                                {offerHeaderTextWithNoPrice}
                            </Text>
                        </div>
                    </>
                ) : (
                    <Text
                        tag="span"
                        variant="h2"
                        className={`${styles.packageCost} ${styles.flat}`}
                        style={{ color: offerPromotionColor || '' }}
                    >
                        {packageCostDuration} month{packageCostDuration === 1 ? ' ' : 's '}
                        {packageCost === 0 ? 'Free' : `for $${packageCost}`}
                    </Text>
                )}
            </div>
        </div>
    );
}
