import { type Alignment, Headline } from '@/components/molecules/Headline';
import { RenderRTE } from '@/components/RenderRTE';
import styles from './header.module.scss';
import { Cols } from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid';
import LandingTitle from '../LandingTitle';

interface Props {
    index?: number;
    asset: SiteHeader;
    presentation?: SiteEntryPresentation;
    asW2?: boolean;
}

export default function Header({ asset, index, presentation, asW2 }: Props): JSX.Element {
    const headlineTag = index === 0 ? 'h1' : 'h2';
    const subhead: JSX.Element | undefined = asset.fields.intro_copy ? (
        <RenderRTE contentfulDoc={asset.fields.intro_copy} asW2={asW2} />
    ) : undefined;
    let columns: Cols = 10;
    let align: Alignment = 'left';

    if (presentation) {
        if (presentation.styles.Columns === '6 Columns') {
            columns = 6;
        } else if (presentation.styles.Columns === '12 Columns') {
            columns = 12;
        }

        align = (presentation.styles.Alignment?.toLowerCase() as Alignment) || 'left';
    }

    const mainContainerStyles =
        presentation?.styles.Display?.toLowerCase() === 'hideonmobile' ? styles.mainContainer : '';

    const isPulseTemplate = presentation?.template === 'Pulse';
    if (isPulseTemplate) {
        const subhead: JSX.Element | undefined = asset.fields.intro_copy ? (
            <RenderRTE contentfulDoc={asset.fields.intro_copy} asW2={asW2} />
        ) : undefined;

        return (
            <LandingTitle subtitle={subhead}>
                <RenderRTE contentfulDoc={asset.fields.headline} asW2={asW2} />
            </LandingTitle>
        );
    }

    return (
        <Headline
            desktopCols={columns}
            tag={headlineTag}
            eyebrow={asset.fields.eyebrow}
            subhead={subhead}
            standardMoleculeSpacing
            align={align}
            mainContainerClassName={mainContainerStyles}
        >
            <RenderRTE contentfulDoc={asset.fields.headline} asW2={asW2} />
        </Headline>
    );
}
