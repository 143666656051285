import styles from './landingtitle.module.scss';
import dynamic from 'next/dynamic';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
interface Props {
    index?: number;
    subtitle: string | React.ReactNode;
    children: React.ReactNode;
}

const LandingTitle = ({ subtitle, children }: Props) => {
    return (
        <div className={styles.grid_container}>
            <Grid container>
                <Grid col={{ xs: 12, sm: 12, md: 12, lg: 12 }}>  
                    <div className={styles.title_pane}>
                        <div className={styles.content}>
                            <div className={styles.title}>{children}</div>
                            <div className={styles.subtitle}>{subtitle}</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default LandingTitle;
